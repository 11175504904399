import React from 'react';
import {BrowserRouter, Routes as Router, Route} from 'react-router-dom';
import Main from './components/PaginaInicial/Main';
import TayPage from './components/TayPage';

function Routes(){

    return(
        <BrowserRouter>
            <Router>
                <Route path="/" element={<Main />}/>
                <Route path="/Taynara" element={<TayPage />}/>
            </Router>
        </BrowserRouter>
    );
 };
export default Routes;

