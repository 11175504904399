import '../assets/css/tayPage.css'
import '../assets/css/colors.css'
import '../assets/css/fonts.css'
import { useEffect, useReducer, useRef, useState, Fragment } from 'react'



const IntroButton = ({setChangeText, playButtonRef, handlePlay})=>{
  const [showDiv, setShowDiv] = useState(false)

  useEffect(() => {
    if(showDiv == true){
      setTimeout(() => { setChangeText(1)  }, 2000)
    }
  }, [showDiv]);

  return <div className="textoIntroBtn animaIntroButton" 
              onClick={() => { setShowDiv(1)  
                handlePlay()
              }}
              style={showDiv ? {transform: 'translateY(-500%)'} : {transform: 'translateY(0)'}}>
          <div className="button" ref={playButtonRef} onClick={handlePlay}>CLICA AI</div>
        </div>
}


const IntroText = ({setChangeText})=>{
    
    const [currentText, setCurrentText] = useState('')
    const [currentTextIndex , setCurrentTextIndex ] = useState(0)
    const delay = 60
    const text = 'NÃO SEI COMO INICIAR, NÃO SEI COMO VAI TERMINAR, <br /> MAS DE UMA COISA EU TENHO CERTEZA, ESSE SITE É PARA VOCÊ AMOR'

    const [showHeart, setShowHeart] = useState(false)

    useEffect(() => {
        if (currentTextIndex < text.length) {
          const timeout = setTimeout(() => {
            setCurrentText(prevText => prevText + text[currentTextIndex]);
            setCurrentTextIndex(prevIndex => prevIndex + 1);
          }, delay);
      
          return () => clearTimeout(timeout);
        }else{
            setShowHeart(true)
            setTimeout(() => { setChangeText(2)  }, 3000)
        }
      }, [currentTextIndex, delay, text]);

      const formattedText = currentText.split('<br />').map((part, index) => (
        <Fragment key={index}>
          {part.split("TERMINAR,")[0] + (part.split("TERMINAR,").length > 1 ? "TERMINAR," : '')}
          {index !== currentText.split('<br />').length - 1 && <br />}
        </Fragment>
      ));

    return <div className="textoIntro animaIntroTexto">
        {formattedText}
        <div className="heartImg" style={showHeart ? {opacity: 1} : {opacity: 0}}></div>
    </div>
}


const TextoMid1 = ({setChangeText})=>{

    const [showDiv, setShowDiv] = useState(false)
    const [showDiv2, setShowDiv2] = useState(false)
    const [showDiv3, setShowDiv3] = useState(false)

    useEffect(() => {
        setTimeout(() => { setShowDiv(1)  }, 500)
        setTimeout(() => { setShowDiv2(1)  }, 5500)
        setTimeout(() => { setShowDiv3(1)  }, 8500)
        setTimeout(() => { setChangeText(3)  }, 16500)
      }, []);

    return <div className="textoMid1" >
            <div style={showDiv ? {transform: 'translateX(0%)'} : {transform: 'translateX(-500%)'}}>
              PENSEI EM VARIAS CANTADAS, TEXTOS E POEMAS,
              <span style={showDiv2 ? {opacity: '1'} : {opacity: '0'}}>
              MAS OQUE ME VEIO A CABEÇA FOI VOCÊ</span>
              </div>
              

            <div className="tayImage" style={showDiv2 ? {opacity: 1, transform: 'scale(1)'} : {opacity: 0, transform: 'scale(0)'}}></div>

            <div style={showDiv3 ? {transform: 'translateX(0%)'} : {transform: 'translateX(-500%)'}}>
              OS SEUS GOSTOS COMO A TORTA DE ABACAXI, DOCE DE LEITE, LIRIOS, ROCK, PAGODE... 
              </div>
        </div>
}


const TextoMid4 = ({setChangeText})=>{

  const [showDiv, setShowDiv] = useState(false)
  const [showDiv2, setShowDiv2] = useState(false)

  useEffect(() => {
      setTimeout(() => { setShowDiv(1)  }, 500)
      setTimeout(() => { setShowDiv2(1)  }, 2500)
      setTimeout(() => { setChangeText(4)  }, 11500)
    }, []);

  return <div className="textoMid4" >
          <div style={showDiv ? {opacity: 1, transform: 'scale(1)'} : {opacity: 0, transform: 'scale(0)'}}>
            O MODO COMO ME OLHA, SUA VOZ, O JEITO COMO ME BEIJA, SÃO VARIAS COISAS.</div>

          <div className="busImage" style={showDiv ? {opacity: 1, transform: 'scale(1)'} : {opacity: 0, transform: 'scale(0)'}}></div>

          <div style={showDiv2 ? {opacity: 1, transform: 'scale(1)'} : {opacity: 0, transform: 'scale(0)'}}>
           E OQUE ME DEIXA MAIS FELIZ, É QUE QUANDO PAREI DE PROCURAR ALGUÉM, ACHEI JUSTAMENTE A MULHER QUE TANTO DESEJEI
            </div>
      </div>
}


const TextoMid2 = ({setChangeText})=>{

    const [showDiv, setShowDiv] = useState(false)
    const [showDiv2, setShowDiv2] = useState(false)

    useEffect(() => {
        setTimeout(() => { setShowDiv(1)  }, 500)
        setTimeout(() => { setShowDiv2(1)  }, 1000)
        setTimeout(() => { setChangeText(5)  }, 6000)
      }, []);

    return <div className="textoMid2" >
            <div style={showDiv2 ? {opacity: 1} : {opacity: 0}}>EU PODERIA VOLTAR AO PASSADO, MAS EU SÓ PENSO EM UM FUTURO COM VOCÊ</div>
            <div className="clockImage" style={showDiv ? {opacity: 1, transform: 'rotate(0deg)'} : {opacity: 0, transform: 'rotate(180deg)'}}></div>
        </div>
}



const TextoMid3 = ({setChangeText})=>{

    const [showDiv, setShowDiv] = useState(false)

    useEffect(() => {
        setTimeout(() => { setShowDiv(1)  }, 500)
        //setTimeout(() => { setChangeText(3)  }, 6000)
      }, []);

    return <div className="textoMid3" style={showDiv ? {opacity: 1} : {opacity: 0}}>
            <div className="textBase title" >Taynara Loiola Lacerda</div>
            <div className="textBase sub">Eu Te amo!</div>
            <div className="heartImg"></div>
        </div>
}


export default function TayPage(props){

    const stars = Array(50).fill(0)

    const [changeText, setChangeText] = useState(0)

    const audioRef = useRef(null);
    const playButtonRef = useRef(null);
  
    
      const handlePlay = () => {
          console.log("HELLO")
          audioRef.current.play();
      };


    const renderText = ()=>{
        if(changeText === 0){
        return <IntroButton setChangeText={(val)=>{setChangeText(val)}}
                  playButtonRef={playButtonRef} handlePlay={handlePlay}/>
        }else if(changeText === 1){
            return <IntroText setChangeText={(val)=>{setChangeText(val)}}/>
        }else if(changeText === 2){
            return <TextoMid1  setChangeText={(val)=>{setChangeText(val)}}/>
        }else if(changeText === 3){
            return <TextoMid4  setChangeText={(val)=>{setChangeText(val)}}/>
        }else if(changeText === 4){
          return <TextoMid2  setChangeText={(val)=>{setChangeText(val)}}/>
        }else if(changeText === 5){
            return <TextoMid3  setChangeText={(val)=>{setChangeText(val)}}/>
        }
    }


    return <>
    
        <div className="containerPrincipal TayPage css-backAnima">
            <audio ref={audioRef} controls style={{opacity: 0}}>
            <source src={require('../assets/audio/teste.mp3')} type="audio/mp3" />
            Seu navegador não suporta a tag de áudio.
        </audio>
            <div className="backgroundStars">
            {stars.map((_, index) => {
                    // Gera posições aleatórias para cada estrela
                    const randomTop = Math.floor(Math.random() * 100); // entre 0 e 100%
                    const randomLeft = Math.floor(Math.random() * 100); // entre 0 e 100%
                    const randomDelay = Math.random() * 3;
                    return (
                        <div
                            key={index}
                            className="star"
                            style={{
                                '--randomTop': randomTop+"%",
                                '--randomLeft': randomLeft+"%",
                                '--randomSize': (Math.floor((index%2)+2.7))+"px",       
                                '--randomSize': (Math.floor((index%2)+2.7))+"px",  
                                animationDelay: `${randomDelay}s`,                          
                            }}
                        ></div>)
                })}
            </div>

                <div className="textCenter">
                    {renderText()}
                </div>

                


                


        </div>
    </>
}